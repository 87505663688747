<template>
  <!--
    The view for the DeviceList and LocalDeviceManagement-components.
    Used to display and manage the installations devices.
  -->
  <div class="installationDetail_Devices">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <div class="card">
        <Portlet
          :title="$t('installationDetailDevices.devices')"
          icon="hdd"
        >
          <template slot="buttons">
            <button 
              v-if="authenticationHasRole('scope_staff') && (authenticationHasRole('secondlevel') || authenticationHasRole('admin'))"
              class="btn btn-sm btn-primary float-right mt-2 mb-2"
              @click="startZabbixSync"
            >
              <span>
                Zabbix Sync
              </span>
            </button>
          </template>
          <DeviceList :installation-id="installationId" />
        </Portlet>
      </div>
    </template>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';
export default {
  name: "InstallationDetailDevices",
  components: {
    DeviceList: () => import('@/components/Device/DeviceList.vue')
  },
  mixins: [
    authenticationMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      installationObject: null,
      loading: true
    }
  },
  metaInfo () {
    return {
      title: this.$t('installationDetailDevices.devices')
    }
  },
  created () {
    this.getInstallation();
  },
  methods: {
    startZabbixSync () {
      this.axios.get(`/MonitoringSystem/StartZabbixSync?installationId=${ this.installationId }`)
      .then((response) => {
        if(response && response.status == 200) {
          this.$snotify.success("Zabbix Sync started.");
        }
        else {
          this.$snotify.success("Error while starting Zabbix Sync.");
        }
      })
    },
    getInstallation () {
      this.loading = true;
      this.axios.get('/Installation/Get?id=' + this.installationId)
        .then((response) => {
          this.installationObject = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>